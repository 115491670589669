.skills_card {
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  text-align: center;
  color: #091a38;
  width: 100%;
}
.skills_card__heading {
  margin: 0;
  margin-bottom: 8px;
}
.skills_card__description {
  margin: 0;
}
