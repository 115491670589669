.project_card {
  border-radius: 2px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.project_card__image {
  width: 100%;
  object-fit: cover;
  max-height: 200px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  transition: 0.7s;
}

.project_card__image:hover {
  opacity: 0.5;
}

.project_card__details {
  padding: 20px;
  color: #091a38;
}
.project_card__heading {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  text-decoration: none;
  color: #091a38;
}

.project_card__heading_link {
  text-decoration: none;
  color: #091a38;
}
.project_card__description {
  margin: 0;
  font-size: 14px;
}
