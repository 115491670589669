.social_links {
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .social_links {
    display: block;
    text-align: right;
  }
  .social_links__link {
    margin-right: 0;
  }
}

.social_links__link {
  color: white;
  font-size: 45px;
  margin-right: 10px;
}
