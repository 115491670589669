*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
  background-image: linear-gradient(
    to right top,
    #051937,
    #4d3156,
    #8d4e66,
    #c1756d,
    #e2a976
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.skills__item {
  display: flex;
  align-items: stretch;
  margin-top: 40px;
}

.projects__item {
  display: flex;
  align-items: stretch;
  margin-top: 40px;
}
