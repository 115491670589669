.footer {
  margin-top: 20px;
}

.space_col {
  padding: 0;
  margin: 0;
}

.footer__content {
  font-size: 25px;
  color: white;
  align-items: center;
}

.footer__content_normal {
  font-weight: 200;
}

.footer__content_bold {
  font-weight: 500;
}
